
main, div.ReactModalPortal {
  h1, h2, h3, h4 {
    letter-spacing: -0.02em;
  }

  h1 {
    @include font-sizes-important($GillSansLight, 64, 77);
    font-weight: 300;
  }

  h2 {
    @include font-sizes-important($GillSansLight,48, 58);
    font-weight: 300;
  }

  h3 {
    @include font-sizes-important($GillSansLight, 40, 48);
  }

  h4 {
    @include font-sizes-important($GillSansLight,32, 36);
    font-weight: 400;
  }

  h5 {
    @include font-sizes-important($GillSansLight,24, 31);
    font-weight: 400;
  }

  .BodyShort {
    @include font-sizes-important($RobotoRegular,18, 23);

    &.Strong {
      @include font-sizes-important($RobotoMedium,18, 23);
    }
  }

  .Body {
    @include font-sizes-important($RobotoRegular,16, 24);

    &.Strong {
      @include font-sizes-important($RobotoMedium,16, 21);
    }
  }

  .BodyLittle {
    @include font-sizes-important($RobotoRegular,13, 17);

    &.Active {
      text-decoration: underline;
    }

    &.Strong {
      @include font-sizes-important($RobotoMedium,13, 17);
    }
  }

  .BodyBig {
    @include font-sizes-important($RobotoRegular,24, 34);
    
    &.Strong {
      @include font-sizes-important($RobotoBold,24, 34);
    }
  }

  .BodyComponentDesc {
    @include font-sizes-important($RobotoRegular,24, 31);
  }

  @media (min-width: $breakpoint-m) {
    h1, h2, h3, h4 {
      letter-spacing: -0.01em;
      font-weight: 300;
    }

    h1 {
      @include font-sizes-important($GillSansLight,112, 146);
    }

    h2 {
      @include font-sizes-important($GillSansRegular,80, 96); 
    }

    h3 {
      @include font-sizes-important($GillSansRegular, 64, 83); 
    }

    h4 {
      @include font-sizes-important($GillSansLight, 48, 62); 
    }
  }
}
