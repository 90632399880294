.links-list-module__main--items-left {
  .form {
    display: none;
  }
}

.content-main.search-content > .col-12.search-title {
  display: none;
}

.col-6.search-fieldset-wrapper {
  margin-top: 48px;
}
 
img.page-header-img {
  background: gray;
}

.search-no-results-wrapper {
  min-height: 200px;
  width: 100%;
  height: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;

  h5 {
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    vertical-align: center;
  }
}

h1.texting-module__title {
  padding-left: 20px !important;
}

.text-module--left {
  float: left;
  padding-left: 42px !important;
}

.text-module-- {
  padding-top: 42px !important;
  padding-left: 42px !important;
  padding-right: 42px !important;
  padding-bottom: 42px !important;
}

#loader {
  border: 16px solid #00000033;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.filters-left:first-child {
  margin-left: 0px;
}

.filters-left:last-child {
  margin-right: 0px;
}

.col-12.cp-row.search-all-results,
.text-title,
.promotions-collector-section {
  #loader {
    margin: auto;
    margin-top: 10px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

div.image img {
  max-height: 100%;
  max-width: 100%;
  height: 100%;
}

.accordion-module {
  margin-bottom: 32px;
}

.accordion-wrapper {
  .texting-module__text {
    padding-left: 32px !important;
  }
}

.card-carousel-section.text-title {
  clear: both;
}

.slick-dots {
  margin-bottom: 45.5px;
  // li {
  //   background-color: #f5f5f5 !important;
  // }
}

.styled-select-container {
  padding-top: 10px;
  padding-left: 10px;
  display: block;

  .styled-select {
    // background: url(http://i62.tinypic.com/15xvbd5.png) no-repeat 96% 0;
    // background: url(http://i62.tinypic.com/2e3ybe1.jpg) no-repeat right center;
    // height: 29px;
    width: auto;
  }

  .icon {
    padding-top: 10px;
  }
}

.styled-select-container-mobile {
  padding-top: 0px;
  padding-left: 13px;
  display: block;
  height: 40px;

  .styled-select-mobile {
    // background: url(http://i62.tinypic.com/15xvbd5.png) no-repeat 96% 0;
    // background: url(http://i62.tinypic.com/2e3ybe1.jpg) no-repeat right center;
    // height: 29px;
    width: auto;
  }

  .icon {
    padding-top: 0px;
  }
}

#changeLanguageSelect,
#changeLanguageSelectMobile {
  display: block;
  margin-top: -34px;
  background: transparent;
  -webkit-appearance: none;
  padding-left: 28px !important;
  box-shadow: none
;
 .language {
    &__control{
      background: transparent;
      border-color: unset;
      cursor: pointer;
      box-shadow: unset;
      width: 5rem;
    }

    &__menu {
      background: black;
      color: white;
      width: 5rem;
      margin-top: -3px;
      z-index: 9999;
      cursor: pointer;
    }

    &__option{
      &--is-focused, &--is-selected {
        background-color: #1554ff;
      }
    }

    &__single-value {
      color: #9e9e9e;
    }
  }
}

.card-grid-title {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-link {
  width: auto !important;
}

.styled-select-container .icon svg path,
#accessories_Home_globalMenu__link .link-simple .icon svg path {
  stroke: #1554ff;
}

.text-module__icon--desktop {
  display: none !important;
}

.text-module__icon--mobile {
  display: none !important;
}
