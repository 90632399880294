@import "../styles/root";

@font-face {
  font-display: swap;
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/Roboto-Regular.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Medium";
  src: url("../assets/fonts/roboto/Roboto-Medium.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Bold";
  src: url("../assets/fonts/roboto/Roboto-Bold.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Roboto-Light";
  src: url("../assets/fonts/roboto/Roboto-Light.woff2") format("truetype");
}

@font-face {
  font-display: swap;
  font-family: "Gill Sans";
  src: url("../assets/fonts/gill-sans/Gill-Sans.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Gill Sans Light";
  src: url("../assets/fonts/gill-sans/Gill-Sans-Light.otf") format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Gill Sans Medium";
  src: url("../assets/fonts/gill-sans/Gill-Sans-Medium.otf") format("opentype");
}


.Desktop36px_Title-section-primary02_Greyscale05_Black {
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Desktop24px_Title-article02_Greyscale05_Black {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Desktop18px_Subtitle-article01_Primary1_Blue {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #1554fe;
}

.Mobile14px_Body302_Greyscale04_Light-Black {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--light-black-9);
}

.Mobile16px_Body102_Greyscale05_Black {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Note02_Greyscale04_Light-Black-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--light-black-9);
}

.Note02_Greyscale03_Dark-Grey-2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--dark-grey-8);
}

.Mobile34px_Title02_Greyscale05_Black {
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Mobile20px_Title-article02_Greyscale05_Black {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: var(--total-black-10);
}

.Mobile14px_Body302_Greyscale05_Black {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--total-black-10);
}