// @import "../node_modules/@patternfly/pfelement-onhighway/dist/pfelement";
@import "./lib/css/slick/slick.scss";
// @import "./lib/css/slick/slick-theme.scss";
@import "./styles/variables";
@import "./styles/mixins";
@import "./styles/font";
@import "./styles/headlines";

// initial theme -> to be configured in ThemeContext/index.js and index.html
// @import "../node_modules/@patternfly/pfe-styles-onhighway/dist/pfe-theme--case";
/// ===================================================
/// CONTAINERS AND BACKGROUNDS. USAGE:
/// <div class="bg-default">
///   <div class="container">
///     <p>Content</p>
///   </div>
/// </div>
/// ===================================================
* {
  touch-action: manipulation;
}

html {
  scroll-behavior: smooth;
}

body {
  color: initial;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: none) and (pointer: coarse) {
  .ReactModal__Content {
    touch-action: pan-y;
  }
}

main {
  position: relative;
}

button {
  border: none;
}

input {
  border: none;
}

fieldset {
  border: none;
}

select {
  border: none;
}

textarea {
  border: none;
}

.loading-spinner {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  position: fixed;
  overflow: hidden;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
}

.small,
.medium,
.high {
  position: relative;

  &:before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9;
  }

  div,
  section,
  button {
    z-index: 10;
  }

  > img:not(.hero-slider-img):not(.page-header-img):not(.carousel-img):not(
      .banner-img
    ):not(.big-card-img) {
    z-index: 10;
  }
}

.small::before {
  background: rgba(255, 255, 255, 0.1);
}

.medium::before {
  background: rgba(255, 255, 255, 0.2);
}

.high::before {
  background: rgba(255, 255, 255, 0.4);
}

.dark {
  h1,
  .description {
    color: $color-white;
  }

  &.small:before {
    background: rgba(0, 0, 0, 0.1);
  }

  &.medium:before {
    background: rgba(0, 0, 0, 0.2);
  }

  &.high:before {
    background: rgba(0, 0, 0, 0.4);
  }
}

.light {
  h1,
  .description {
    color: $color-black;
  }
}

#root {
  max-width: 1920px;
  overflow-x: hidden;
  margin: 0 auto;
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

img {
  display: block;
}

.section-space {
  padding-top: 64px;
  padding-bottom: 96px;

  .cp-row > *:not(:last-of-type):not(.col-6) {
    margin-bottom: 20px;
  }
}

.modular-data-table.section-space {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 64px;
  margin-bottom: 96px;
}

.rc-anchor-invisible {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

.vehicle-card-logo {
  img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: (1136px - 0.2)) {
  .header-bar-container {
    height: 60px;
    //overflow: hidden;
  }
}

@media screen and (min-width: 1136px) {
  .header-bar-container {
    height: 73px;
    overflow: hidden;
  }
}

.form.contactUs {
  .form_message_section {
    min-width: 100%;
    margin-bottom: 33px !important;

    .form_message {
      width: 100%;
      padding: 10px;
      outline-color: #1554ff;
    }
  }
}

//BUG ALTEZZA
.content-switcher__main-items .slick-slide[aria-hidden="true"] {
  height: 10px;
}
