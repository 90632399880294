@import "variables";

@mixin line-clamp($lines: 5) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
    display: -webkit-box;
    overflow: hidden;
}

@mixin max-width() {
    margin: 0 auto;
    max-width: $width-max-page;
}

@mixin flex-layout($direction,$justify-content,$align-items){
    display: flex;
    flex-direction: $direction;
    align-items: $align-items;
    justify-content: $justify-content;
}
@mixin media($from: null, $to: null) {
    $from: map-get($breakpoints, $from);
    $to: map-get($breakpoints, $to);
    $gap: 0.5px;
    @if $from and $to {
        @media (min-width: $from) and (max-width: ($to - $gap)) {
            @content;
        }
    }
    @else if $from {
        @media (min-width: $from) {
            @content;
        }
    }
    @else if $to {
        @media (max-width: ($to - $gap)) {
            @content;
        }
    }
    @else {
        @content;
    }
}

@mixin power-bar($width: 100%) {
    background: $color-primary;
    content: '';
    display: block;
    height: 0;
    margin-right: $margin-base * 2;
    transform: skew(-25deg);
    transition: height 0.25s ease-out;
    width: $width;
}

@mixin visually-hidden() {
    &:not(:focus):not(:active) {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }
}

@mixin fixed-picture-aspect-ratio($pictureWidth, $pictureHeight) {
    display: block;
    padding-top: calc(#{$pictureHeight} / #{$pictureWidth} * 100%);
    position: relative;
    width: 100%;
    img {
        display: block;
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
    font-size: $font-size * 1px;
    @if $line-height==normal {
        line-height: normal;
    }
    @else {
        line-height: $line-height / $font-size;
    }
    @if $letter-spacing==normal {
        letter-spacing: normal;
    }
    @else {
        letter-spacing: #{$letter-spacing / $font-size};
    }
}

@mixin center-both($position: absolute) {
    position: $position;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin center-horizontal($position: absolute) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-vertical($position: absolute) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

@mixin font-sizes($fontFamily ,$font-size, $line-height) {
    font-size: #{($font-size / $base-font-size)}$font-unit;
    line-height: #{($line-height / $base-font-size)}$font-unit;
    font-family: $fontFamily;
}

@mixin font-sizes-important($fontFamily ,$font-size, $line-height) {
    font-size: #{($font-size / $base-font-size)}$font-unit !important;
    line-height: #{($line-height / $base-font-size)}$font-unit !important;
    font-family: $fontFamily !important;
}
  